import { Configuration, PopupRequest } from "@azure/msal-browser";

const clientId = process.env.REACT_APP_CLIENT_ID ?? "";
export const msalConfig: Configuration = {
  auth: {
    clientId: clientId,
    authority: process.env.REACT_APP_AUTHORITY ?? "",
    redirectUri: window.location.origin,
  },
};

const apiScope = `api://${clientId}/app`;
export const loginRequest: PopupRequest = {
  scopes: ["openid", apiScope],
};
